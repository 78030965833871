'use client';

import LabTestOrderSuccess from '@/labtest/presentation/organisms/OrderSuccessModal/LabTestOrderSuccess';
import lottie from 'lottie-web';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { MdClose } from 'react-icons/md';

import animated_graphic from './data.json';

import { IG_DoctorConsultationPaymentInfo } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationPaymentInfo';
import Line from '../../atoms/Line';
import DocConsultationOrderSuccess from './DocConsultationOrderSuccess';
import styles from './OrderSuccessModal.module.scss';

interface I_Props {
	serviceType: string;
	data: any | Partial<IG_DoctorConsultationPaymentInfo>;
	title: string;
	message: string;
	closeOrderSuccessModal: () => void;
	closeCheckoutModal?: () => void;
}

const OrderSuccessModal = ({
	serviceType,
	data,
	title,
	message,
	closeOrderSuccessModal,
	closeCheckoutModal
}: I_Props) => {
	const router = useRouter();

	useEffect(() => {
		if (typeof window !== 'undefined' && typeof document !== 'undefined') {
			const animation = lottie.loadAnimation({
				loop: true,
				renderer: 'svg',
				animationData: animated_graphic,
				container: document.getElementById('animated_graphic')
			});
			return () => animation.destroy();
		}
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.icon_container_wrap}>
				<div className={styles.order_success_graphic} id='animated_graphic'></div>

				<MdClose
					size={24}
					color='var(--ltErrorBase)'
					style={{
						position: 'absolute',
						top: '20px',
						right: '20px',
						cursor: 'pointer'
					}}
					onClick={closeOrderSuccessModal}
				/>

				<div className={styles.content_container}>
					<h1>{title}</h1>
					<p>{message}</p>
				</div>
			</div>

			<Line />
			<div>
				{serviceType === 'lab-test' && (
					<LabTestOrderSuccess data={data} closeOrderSuccessModal={closeOrderSuccessModal} />
				)}

				{serviceType === 'doc-consultation' && (
					<DocConsultationOrderSuccess data={data} closeOrderSuccessModal={closeOrderSuccessModal} />
				)}
			</div>
		</div>
	);
};

export default OrderSuccessModal;
